.header-container {
    display: flex;
    flex-flow: row;
    background-color: black;
    width: 100%;
    height: 5rem;
    top: 0;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.logo-container {
    width: 50%;
    display: flex;
    flex-flow: row;
    justify-content: left;
    align-items: left;
    vertical-align: middle;
}

.header-logo {
    color: white;
    font-family: 'Komikax';
    padding: 0.5rem;
    margin: 1rem;
    font-size: 2rem;
}

.header-logo:hover {
    cursor: pointer;
}

.nav-container {
    width: 50%;
    display: flex;
    flex-flow: row;
    justify-content: right;
    align-items: right;
    vertical-align: middle;
}

.nav-item {
    color: white;
    font-family: 'Komikax';
    padding: 0.5rem;
    margin: 0.25rem;
    font-size: 1.5rem;
}

.nav-item:hover {
    cursor:pointer;
}