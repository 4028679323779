.process-img-src {
    width: 20rem;
    height: 20rem;
    border-radius: 20% 20% 20% 20%;
    border-width: 0.25rem;
    border-style: solid;
    border-color: gold;
    margin: 1rem;
    object-fit: cover;
}

@media screen and (max-width: 800px) {
    .process-img-src {
        width: 10rem;
        height: 10rem;
        border-radius: 20% 20% 20% 20%;
        border-width: 0.25rem;
        border-style: solid;
        border-color: gold;
        margin: 1rem;
        object-fit: cover;
    }
}
