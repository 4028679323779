.project-button {
    background-color: #3a3a3a;
    border-radius: 20% 20% 20% 20%;
    border-width: 0.25rem;
    border-style: solid;
    border-color: gold;
    font-family: Komikax;
    color: white;
    font-size: 1.5rem;
    padding: 1rem;
    font-size: 1rem;
    height: 10rem;
    width: 15rem;
    margin: 0.5rem;
}

.project-button:hover {
    background-color: #A5FEFC;
    border-color: black;
    color: #3a3a3a;
    cursor: pointer;
}
