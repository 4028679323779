.circle {
    background-size: cover;
    border-radius: 50% 50% 50% 50%;
    border-width: 0.25rem;
    border-style: outset;
    border-color: gold;
    width: 15rem;
    height: 15rem;
    overflow: hidden;
}

.img-src {
    width: 15rem;
    height: 15rem;
    object-fit: cover;
}

@media screen and (max-width: 800px) {
    .circle {
        background-size: cover;
        border-radius: 50% 50% 50% 50%;
        border-width: 0.25rem;
        border-style: outset;
        border-color: gold;
        width: 10rem;
        height: 10rem;
        overflow: hidden;
    }
    
    .img-src {
        width: 10rem;
        height: 10rem;
        object-fit: cover;
    }
}
