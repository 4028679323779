.mobile-header-container {
    display: flex;
    flex-flow: row;
    background-color: black;
    width: 100%;
    height: 5rem;
    top: 0;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.mobile-header-logo {
    height: 5rem;
}