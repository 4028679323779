.app {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.content {
    margin-top: 5rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.section-title {
    color: white;
    font-family: "Komikax";
    font-size: 3rem;
}

.section-text {
    color: white;
    font-family: "Lato";
    font-size: 1.5rem;
}

.logo-section {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.about-section {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
    padding: 3rem;
}

.process-section {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem;
}

.process-step-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    width: 75%;
}

.process-step-text-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.process-step-title {
    color: gold;
    font-family: "Komikax";
    font-size: 2rem;
}

.team-section {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem;
}

.teammate-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem;
}

.teammate-img {
    padding: 3rem;
}

.teammate-info {
    width: 50%;
    margin: 3rem;
    display: flex;
    flex-flow: column;
    text-align: left;
}

.teammate-title {
    color: white;
    font-family: "Komikax";
    font-size: 2rem;
}

.teammate-subtitle {
    color: gold;
    font-family: "Komikax";
    font-size: 1rem;
}

.contact-section {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem;
}

.contact-text-container {
    margin: 0.25rem;
    border-width: 0.25rem;
    border-style: dashed;
    border-color: gold;
    padding: 2rem;
}

.contact-text {
    color: white;
    font-family: "Lato";
    font-size: 2.5rem;
}

.main-logo {
    width: 50rem;
}

.logo-text {
    color: #a5fefc;
    font-family: "Komikax";
    font-size: 3rem;
}

.projects-section {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem;
    width: 90%;
}

@media screen and (max-width: 800px) {
    .main-logo {
        width: 25rem;
    }

    .section-title {
        color: white;
        font-family: "Komikax";
        font-size: 2rem;
    }

    .section-text {
        color: white;
        font-family: "Lato";
        font-size: 1rem;
    }

    .teammate-container {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 3rem;
    }

    .contact-text {
        color: white;
        font-family: "Lato";
        font-size: 1rem;
    }

    .teammate-title {
        color: white;
        font-family: "Komikax";
        font-size: 2rem;
    }

    .teammate-subtitle {
        color: gold;
        font-family: "Komikax";
        font-size: 1rem;
    }

    .teammate-info {
        width: 90%;
        margin: 1rem;
        display: flex;
        flex-flow: column;
        text-align: left;
    }

    .process-step-title {
        color: gold;
        font-family: "Komikax";
        font-size: 1.5rem;
    }

    .process-section {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
